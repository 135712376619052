
body {
  font-family: Arial, sans-serif;
  background-color: #32344e;
}

h1 {
  color: #faf6f6;
  text-align: center;
}

.component{
  color: #f7f6f6;
  text-align: center;
}

/* App.css */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #030000;
}

/* App.css */
.nav-link {
  color: #faf6f6; /* Color para todos los enlaces */
  text-decoration: none;
}

.nav-link:hover {
  color: #e2f107; /* Cambia de color cuando se pasa el mouse por encima */
}

/* Estilo para el contenedor del gráfico */
.graph-container {
  display: flex;
  justify-content: center; /* Centra el gráfico horizontalmente */
  margin-bottom: 20px; /* Espacio debajo del gráfico */
}

/* Estilo para el espaciado entre el gráfico y la tabla */
.spacing {
  margin-bottom: 20px; /* Espacio entre el gráfico y la tabla */
}

/* Puedes agregar otros estilos aquí si es necesario */


